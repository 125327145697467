// 维护记录
<template>

    <div class='safeguardRecord baseBg'>
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="处理人">
                    <el-input v-model="searchForm.operatorName"
                              placeholder="请输入处理人"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="目标客户">
                    <customer-or-provider-select-tree ref="selectTree"
                                                      type="CUSTOMER"
                                                      :shouldClean="true"
                                                      @handClear="handClear"
                                                      @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                </el-form-item>
                <el-form-item label="解决时间">
                    <el-date-picker v-model="timeArr"
                                    value-format="yyyy-MM-dd"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData.data"
                          border
                          :height="tableHeight"
                          stripe>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     :min-width="col.width">
                    </el-table-column>
                    <el-table-column prop="id"
                                     label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">查看</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>

        <!-- 弹框 -->
        <selectPerson v-if="selectPersonVisible"
                      @close="closeselectPerson"></selectPerson>

    </div>

</template>

<script>
import CustomerOrProviderSelectTree from "../../components/selectTree/customerOrProviderSelectTree";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import selectPerson from "./components/popSelectPerson";
import API from "@/api/safeguardManage.js";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "safeguardRecord",

    props: [],

    components: {
        CustomerOrProviderSelectTree,
        Pagination,
        selectPerson,
    },

    data() {
        return {
            selectPersonVisible: false, // 选择人员弹窗显示
            // 筛选表单
            timeArr: [], // 选择日期范围
            searchForm: {
                operatorName: "", // 处理人员
                systemId: "", // 目标客户
                resolveTimeQueryStartTime: "",
                resolveTimeQueryEndTime: "",
                page: 1,
                size: 20,
                waitResolveFlag: false, // 是否查询维护管理列表，传true则查询维护管理列表，传false则查询维护记录列表
            },
            pageTotal: 0, // 分页总数
            typeOpts: [{ label: "全部", value: "" }],
            time: [],
            tableData: {
                columns: [
                    {
                        id: "type",
                        label: "维护类型",
                        width: "100",
                    },
                    {
                        id: "customerName",
                        label: "目标客户",
                        width: "100",
                    },
                    {
                        id: "customerDefaultLinkName",
                        label: "负责人",
                        width: "100",
                    },
                    {
                        id: "linkName",
                        label: "报修人",
                        width: "100",
                    },
                    {
                        id: "linkPhone",
                        label: "联系方式",
                        width: "100",
                    },
                    {
                        id: "createTime",
                        label: "报修时间",
                        width: "100",
                    },
                    {
                        id: "resolveTime",
                        label: "解决时间",
                        width: "100",
                    },
                    {
                        id: "belongDeptName",
                        label: "处理部门",
                        width: "100",
                    },
                    {
                        id: "operatorName",
                        label: "处理人员",
                        width: "100",
                    },
                ],
                data: [],
            },
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 维护记录 列表
        this.getList();
    },

    mounted() {
        // 维护记录 列表
        this.getList();
    },

    methods: {
        // 目标客户的选择
        selectTreeCustClick(node) {
            this.searchForm.systemId = node.id;
        },
        // 目标客户的清空
        handClear() {
            this.searchForm.systemId = "";
        },
        // 移交任务
        change(row) {
            this.nowRow = row;
            this.selectPersonVisible = true;
        },
        // 关闭移交任务
        closeselectPerson(name, id) {
            if (id) {
                let loading = Loading.service({
                    target: document.querySelector(".tableBox"),
                });
                API.changeOperatorsafeguardRecord({
                    operatorId: id,
                    maintainId: this.nowRow.id,
                })
                    .then(() => {
                        this.$message({
                            message: "操作成功！",
                            type: "success",
                        });
                        this.nowRow = "";
                        this.getList();
                        loading.close();
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
            this.selectPersonVisible = false;
        },
        // 维护记录 列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            commonAPI
                .listSafeguardManage(this.searchForm)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 新建任务
        addNewTask() {
            this.addNewTaskVisible = true;
        },
        // 关闭新建任务
        closeaddNewTask() {
            this.addNewTaskVisible = false;
        },

        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getList();
        },
        // 跳转任务详情
        details(row) {
            this.$router.push({
                path: "/deviceManage/safeguardDetails",
                query: { id: row.id },
            });
        },

        //清空筛选条件
        resetSearchForm() {
            this.$refs.selectTree.cleanSelect(); //清空供应商
            this.searchForm.operatorName = "";
            this.searchForm.systemId = "";
            this.searchForm.resolveTimeQueryEndTime = "";
            this.searchForm.resolveTimeQueryStartTime = "";
            this.timeArr = [];
            this.getList();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 245 });
        },
    },

    watch: {
        timeArr(val) {
            this.searchForm.resolveTimeQueryStartTime = "";
            this.searchForm.resolveTimeQueryEndTime = "";
            if (val.length && val.length === 2) {
                this.searchForm.resolveTimeQueryStartTime = val[0];
                this.searchForm.resolveTimeQueryEndTime = val[1];
            }
        },
    },
};
</script>

<style lang='scss' scoped>
</style>
